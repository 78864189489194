<template>
  <div>
    <b-modal v-model="isAboutEposActive">
      <div class="is-flex is-justify-content-center">
        <img src="@/assets/images/CE.png" alt="Certified" />
      </div>
    </b-modal>
    <b-navbar type="is-primary" :mobile-burger="singlePatientMode && !!user">
      <template #brand>
        <div class="logo-container" @click="$router.push('/')">
          <img
            src="@/assets/images/logo_epos_inverted.png"
            width="100"
            alt="Logo"
          />
        </div>
      </template>

      <template #start v-if="user">
        <b-navbar-item
          :to="{ name: 'patient-overview' }"
          tag="router-link"
          v-if="
            allowedByScope &&
            currentUser.value.permissions.includes('screening-view')
          "
        >
          {{ $t("header.patients") | titleize }}
        </b-navbar-item>
        <b-navbar-item
          :to="{ name: 'user-overview' }"
          tag="router-link"
          v-if="
            allowedByScope &&
            currentUser.value.permissions.includes('user-manager')
          "
        >
          {{ $t("header.users") }}
        </b-navbar-item>
        <b-navbar-item
          :to="{ name: 'operations-overview' }"
          tag="router-link"
          v-if="
            allowedByScope &&
            currentUser.value.permissions.includes('operation-manager')
          "
        >
          {{ $t("header.clinic_operations") }}
        </b-navbar-item>

        <b-navbar-item
          :to="{ name: 'exports' }"
          tag="router-link"
          href="/exports"
          v-if="allowedByScope && user.permissions.includes('screening-export')"
        >
          {{ $t("header.exports") }}
        </b-navbar-item>
        <b-navbar-item
          :to="{ name: 'api-keys' }"
          tag="router-link"
          v-if="allowedByScope && user.permissions.includes('manage-api-keys')"
        >
          {{ $t("api_keys.title") }}
        </b-navbar-item>
      </template>

      <template #end v-if="user">
        <b-navbar-item v-if="profile" class="environment-info-header">
          {{ profile.tenant_name }}
        </b-navbar-item>
        <b-navbar-item
          v-if="showEnv"
          class="environment-info-header"
          :class="environment"
          :active="false"
        >
          {{ environment | uppercase }}
        </b-navbar-item>

        <b-navbar-item href="#" @click="isAboutEposActive = true">
          {{ $t("header.about_epos") }}
        </b-navbar-item>

        <b-dropdown
          v-model="navigation"
          append-to-body
          aria-role="menu"
          position="is-bottom-left"
          v-if="allowedByScope"
        >
          <template #trigger>
            <a class="navbar-link" role="button">
              <span>{{ $t("header.my_profile") }}</span>
            </a>
          </template>

          <b-dropdown-item custom aria-role="menuitem">
            <div
              class="user-meta"
              v-html="
                richMessage(
                  $t('header.logged_in_as', { user: userDisplay })
                ).toString()
              "
            ></div>
          </b-dropdown-item>
          <hr class="dropdown-divider" />
          <b-dropdown-item custom>
            <b-button
              type="is-primary is-light"
              class="button-with-icon"
              @click="$router.push(`/me`)"
              expanded
            >
              <b-icon icon="cog" />
              <span>{{ $t("profile_page.title") }}</span>
            </b-button>
          </b-dropdown-item>
          <b-dropdown-item custom v-if="profile?.clinic_login_enabled">
            <b-button
              class="button-with-icon"
              type="is-warning is-light"
              @click="$router.push('/clinic')"
              expanded
            >
              <b-icon icon="clinic-medical" />
              <span>{{ $t("header.clinic_login") }}</span>
            </b-button>
          </b-dropdown-item>
          <b-dropdown-item custom>
            <b-button type="is-primary" @click="onLogoutClick" expanded
              >{{ $t("header.logout") }}
            </b-button>
          </b-dropdown-item>
          <b-dropdown-item custom>
            <TenantSwitcher :profile="profile" @afterSwitch="loadProfile" />
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-navbar>
    <PortalTarget name="warnings" />
  </div>
</template>

<script>
import Vue from "vue";
import { currentUser } from "@/utils/authUtil";
import TenantSwitcher from "@/components/TenantSwitcher";
import { getProfile } from "@/api";
import { Cache } from "@/utils/cache";
import { richMessage } from "@/utils/templateFilters";

export default Vue.extend({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  components: { TenantSwitcher },
  inject: ["singlePatientMode"],
  data() {
    return {
      currentUser: currentUser,
      profile: null,
      navigation: true,
      isAboutEposActive: false,
      environment: process.env.VUE_APP_NOVACAIR_ENVIRONMENT,
    };
  },
  computed: {
    allowedByScope() {
      return !this.singlePatientMode;
    },
    showEnv() {
      return this.environment && this.environment !== "production";
    },
    user() {
      // HACK Leave the user empty in the select-tenant route,
      // so we hide the logged in menu before login is complete.
      return this.$route.name !== "select-tenant" ? currentUser.value : null;
    },
    userDisplay() {
      return this.user
        ? `**${this.user.first_name} ${this.user.last_name}** (${this.user.email})`
        : "";
    },
  },
  watch: {
    user(value, oldValue) {
      // This watcher evidently triggers whenever the route changes (even if
      // the user stays exactly the same), meaning we'd be doing an obscene
      // amount of profile calls if we don't do the equality check.
      if (!value || value === oldValue) {
        return;
      }

      this.loadProfile();
    },
  },
  methods: {
    richMessage,
    onLogoutClick() {
      const logoutRedir = currentUser.value.logout_redirect;

      if (!logoutRedir) {
        this.$router.push({ path: "/login", query: {} });
      }

      sessionStorage.removeItem("token");
      Cache.clear();

      currentUser.value = null;

      if (logoutRedir) {
        window.location.href = logoutRedir;
      }
    },
    async loadProfile() {
      const response = await getProfile();
      this.profile = response.data;
    },
  },
  mounted() {
    if (this.user) {
      this.loadProfile();
    }
  },
});
</script>

<style lang="scss">
.environment-info-header {
  padding: 0.8em;
  font-weight: 800;
  position: relative;

  &:before,
  &:after {
    content: "";
    background: white;
    position: absolute;
    height: 50%;
    width: 1px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

nav {
  padding: 0 1em;
  font-weight: 700;

  .navbar-brand {
    align-items: center;
  }

  .navbar-menu {
    &.is-active {
      margin: 0 -1em;
    }

    margin-left: 1em;

    @media only screen and (max-width: 1024px) {
      padding: 0 0.4em;
    }
  }

  .navbar-end {
    align-items: center;
  }

  .logo-container {
    img {
      cursor: pointer;
    }
  }

  .profile {
    color: #4a4a4a !important;
  }

  .dropdown-trigger {
    display: flex;
  }
}

.dropdown-content {
  min-width: 250px !important;

  .button-with-icon {
    > * {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>

<template>
  <card :title="getCardTitle()" :icon="getCardIcon()">
    <template v-slot:header-actions>
      <progress-bar :progress-items="screening.steps" />
    </template>

    <div v-if="screening && screening.details && screening.details.sections">
      <sections :sections="screening.details.sections" />
    </div>
    <span v-else>{{ $t("screening_card.no_screenings_message") }}</span>
    <template
      v-slot:card-footer
      v-if="screening.actions && screening.actions.length > 0"
    >
      <div class="is-flex is-flex-direction-row-reverse screening-card-buttons">
        <template v-for="action in screening.actions">
          <b-button
            :loading="action.key === 'download' && isDownloadActive"
            :type="action.is_primary ? 'is-primary' : 'is-secondary'"
            :key="action.key"
            :icon-left="action.icon"
            @click="onActionButtonClick(action)"
            v-if="!isLocked.value || action.key === 'report'"
            >{{ action.label }}
          </b-button>
        </template>
      </div>
    </template>
    <b-modal
      v-model="isFormModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      :can-cancel="false"
    >
      <template #default>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ activeAction.confirm.title }}</p>
          </header>
          <section class="modal-card-body">
            <p class="mb-4">
              {{ activeAction.confirm.content }}
            </p>
            <Form
              :fields="fields"
              :onSubmitClick="performAction"
              :submitLabel="activeAction.confirm.confirm_button_text"
              v-model="formData"
            >
              <template v-slot:form-actions>
                <b-button @click="isFormModalActive = false"
                  >{{ activeAction.confirm.cancel_button_text }}
                </b-button>
              </template>
            </Form>
          </section>
        </div>
      </template>
    </b-modal>
    <b-modal
      :can-cancel="true"
      has-modal-card
      @close="showPasscode = null"
      :active="showPasscode"
    >
      <ScreeningPasscodeModal
        :info="passCodeInfoText"
        :title="passCodeTitle"
        :passcode="showPasscode"
      />
    </b-modal>
  </card>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import Card from "./Card.vue";
import Form from "./Form/Form.vue";
import { Action, Screening } from "@/types/screening";
import ProgressBar from "./ProgressBar.vue";
import ScreeningPasscodeModal from "./ScreeningPasscodeModal.vue";
import Sections from "./Sections.vue";
import {
  downloadQuestionnaire,
  downloadReport,
  getForm,
  getReportSummary,
  postFormData,
  getData,
} from "@/api";
import { FormField } from "@/types/form";
import eventBus from "@/utils/eventBus";
import i18n from "@/i18n";

export default Vue.extend({
  name: "ScreeningCard",
  components: { Card, ProgressBar, Sections, Form, ScreeningPasscodeModal },
  inject: ["isLocked"],
  props: {
    screening: Object as PropType<Screening>,
    updateScreening: Function,
  },
  data() {
    return {
      isDownloadActive: false,
      isFormModalActive: false,
      showPasscodeModal: false,
      showPasscode: null as null | string,
      passCodeInfoText: null as string | null,
      passCodeTitle: null as string | null,
      fields: [] as FormField[],
      formData: {},
      activeAction: {} as Action,
    };
  },
  computed: {
    patientId(): number | null {
      return parseInt(this.$route.params?.patientId) || null;
    },
  },
  methods: {
    close() {
      this.showPasscodeModal = false;
    },
    getCardTitle() {
      if (this.screening.questionnaire_type === "anesthesia-child") {
        return (
          i18n.t("screening_card.child_questionnaire_title").toString() +
          this.screening.id
        );
      }

      if (this.screening.questionnaire_type === "anesthesia-proms-dash-1") {
        return (
          i18n.t("screening_card.proms_dash_1_questionnaire_title").toString() +
          this.screening.id
        );
      }

      if (this.screening.questionnaire_type === "anesthesia-proms-prwhe-1") {
        return (
          i18n
            .t("screening_card.proms_prwhe_1_questionnaire_title")
            .toString() + this.screening.id
        );
      }

      if (this.screening.questionnaire_type === "anesthesia-proms-dash-2") {
        return (
          i18n.t("screening_card.proms_dash_2_questionnaire_title").toString() +
          this.screening.id
        );
      }

      if (this.screening.questionnaire_type === "anesthesia-proms-prwhe-2") {
        return (
          i18n
            .t("screening_card.proms_prwhe_2_questionnaire_title")
            .toString() + this.screening.id
        );
      }

      return "Screening #" + this.screening.id;
    },
    getCardIcon() {
      return this.screening.questionnaire_type === "anesthesia-child"
        ? "child"
        : "clipboard-list";
    },
    async performAction() {
      if (
        this.activeAction.key === "prepare" ||
        this.activeAction.key === "process" ||
        this.activeAction.key === "process-questionnaire" ||
        this.activeAction.key === "report" ||
        this.activeAction.key === "questionnaire"
      ) {
        this.$router.push(
          `/patient/${this.patientId}/screening/${this.screening.id}/${this.activeAction.key}`
        );
      } else if (this.activeAction.key === "download") {
        try {
          this.isDownloadActive = true;
          await downloadReport(this.screening.id);
          this.isDownloadActive = false;
        } catch (err) {
          this.$buefy.toast.open({
            message: i18n
              .t("screening_card.downloading_report_error_message")
              .toString(),
            type: "is-danger",
          });
          this.isDownloadActive = false;
        }
      } else if (this.activeAction.key === "download-questionnaire") {
        try {
          this.isDownloadActive = true;
          await downloadQuestionnaire(this.screening.id);
          this.isDownloadActive = false;
        } catch (err) {
          this.$buefy.toast.open({
            message: i18n
              .t("screening_card.downloading_questionnaire_error_message")
              .toString(),
            type: "is-danger",
          });
          this.isDownloadActive = false;
        }
      } else if (this.activeAction.key === "copyreportsummary") {
        try {
          const { data } = await getReportSummary(this.screening.id);
          (this as any).$clipboard(data.text ?? data.sections[0].text);
          this.$buefy.toast.open({
            message: i18n
              .t("common.summary_copied_to_clipboard_message")
              .toString(),
            type: "is-success",
          });
        } catch (err) {
          this.$buefy.toast.open({
            message: i18n
              .t("screening_card.downloading_questionnaire_error_message")
              .toString(),
            type: "is-danger",
          });
          this.isDownloadActive = false;
        }
      } else {
        try {
          const { data } = await postFormData(
            this.screening.id,
            this.activeAction.key,
            this.formData
          );
          this.updateScreening(this.screening.id, data);
          this.$buefy.toast.open({
            message: i18n.t("common.perform_action_done").toString(),
            type: "is-success",
          });
          eventBus.$emit("update-patient");
          this.isFormModalActive = false;
        } catch (err) {
          this.$buefy.toast.open({
            message: i18n.t("common.perform_action_error_message").toString(),
            type: "is-danger",
          });
        }
      }
    },
    async handleShowPasscodeAction(action: Action) {
      const { data } = await postFormData<Screening>(
        this.screening.id,
        action.key,
        {}
      );

      this.passCodeTitle = action.confirm.title;
      this.passCodeInfoText = action.confirm.content;

      // The screening passcode will be returned as a string value in the result.
      this.showPasscode = (data.action_results ?? [])[0] as string;
    },

    async handleConfirmAction(action: Action) {
      const { data } = await getForm(this.screening.id, action.key);

      if (data?.length > 0) {
        const { data: formData } = await getData(this.screening.id, action.key);
        this.fields = data;
        /* @ts-ignore */
        this.formData = formData;
        this.isFormModalActive = true;
      } else {
        if (action.confirm.confirm_button_text) {
          this.$buefy.dialog.confirm({
            hasIcon: !!action.confirm.icon,
            icon: action.confirm.icon,
            title: action.confirm.title,
            message: action.confirm.content,
            cancelText: action.confirm.cancel_button_text,
            confirmText: action.confirm.confirm_button_text,
            type: "is-info",
            onConfirm: () => {
              this.performAction();
            },
          });
        } else {
          this.$buefy.dialog.alert({
            hasIcon: !!action.confirm.icon,
            icon: action.confirm.icon,
            title: action.confirm.title,
            message: action.confirm.content,
            confirmText: action.confirm.cancel_button_text,
            type: "is-info",
          });
        }
      }
    },

    async handleDirectAccessAction(action: Action) {
      const { data } = await postFormData<Screening>(
        this.screening.id,
        action.key,
        {}
      );

      const url = (data.action_results ?? [])[0] as string;
      window.open(url);
    },

    async onActionButtonClick(action: Action) {
      this.activeAction = action;

      if (action?.key === "show-screening-passcode") {
        await this.handleShowPasscodeAction(action);
      } else if (action.key === "direct-screening-access") {
        await this.handleDirectAccessAction(action);
      } else if (action.confirm) {
        await this.handleConfirmAction(action);
      } else {
        await this.performAction();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.screening-card-buttons {
  display: flex;
  flex-wrap: wrap;

  .button {
    margin-left: 0.5em;
    margin-bottom: 0.5em;
  }
}

.passcode {
  font-size: 1.2rem;
  font-weight: 700;

  .code {
    font-family: monospace;
    white-space: pre;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('b-modal',{model:{value:(_vm.isAboutEposActive),callback:function ($$v) {_vm.isAboutEposActive=$$v},expression:"isAboutEposActive"}},[_c('div',{staticClass:"is-flex is-justify-content-center"},[_c('img',{attrs:{"src":require("@/assets/images/CE.png"),"alt":"Certified"}})])]),_c('b-navbar',{attrs:{"type":"is-primary","mobile-burger":_vm.singlePatientMode && !!_vm.user},scopedSlots:_vm._u([{key:"brand",fn:function(){return [_c('div',{staticClass:"logo-container",on:{"click":function($event){return _vm.$router.push('/')}}},[_c('img',{attrs:{"src":require("@/assets/images/logo_epos_inverted.png"),"width":"100","alt":"Logo"}})])]},proxy:true},(_vm.user)?{key:"start",fn:function(){return [(
          _vm.allowedByScope &&
          _vm.currentUser.value.permissions.includes('screening-view')
        )?_c('b-navbar-item',{attrs:{"to":{ name: 'patient-overview' },"tag":"router-link"}},[_vm._v(" "+_vm._s(_vm._f("titleize")(_vm.$t("header.patients")))+" ")]):_vm._e(),(
          _vm.allowedByScope &&
          _vm.currentUser.value.permissions.includes('user-manager')
        )?_c('b-navbar-item',{attrs:{"to":{ name: 'user-overview' },"tag":"router-link"}},[_vm._v(" "+_vm._s(_vm.$t("header.users"))+" ")]):_vm._e(),(
          _vm.allowedByScope &&
          _vm.currentUser.value.permissions.includes('operation-manager')
        )?_c('b-navbar-item',{attrs:{"to":{ name: 'operations-overview' },"tag":"router-link"}},[_vm._v(" "+_vm._s(_vm.$t("header.clinic_operations"))+" ")]):_vm._e(),(_vm.allowedByScope && _vm.user.permissions.includes('screening-export'))?_c('b-navbar-item',{attrs:{"to":{ name: 'exports' },"tag":"router-link","href":"/exports"}},[_vm._v(" "+_vm._s(_vm.$t("header.exports"))+" ")]):_vm._e(),(_vm.allowedByScope && _vm.user.permissions.includes('manage-api-keys'))?_c('b-navbar-item',{attrs:{"to":{ name: 'api-keys' },"tag":"router-link"}},[_vm._v(" "+_vm._s(_vm.$t("api_keys.title"))+" ")]):_vm._e()]},proxy:true}:null,(_vm.user)?{key:"end",fn:function(){return [(_vm.profile)?_c('b-navbar-item',{staticClass:"environment-info-header"},[_vm._v(" "+_vm._s(_vm.profile.tenant_name)+" ")]):_vm._e(),(_vm.showEnv)?_c('b-navbar-item',{staticClass:"environment-info-header",class:_vm.environment,attrs:{"active":false}},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.environment))+" ")]):_vm._e(),_c('b-navbar-item',{attrs:{"href":"#"},on:{"click":function($event){_vm.isAboutEposActive = true}}},[_vm._v(" "+_vm._s(_vm.$t("header.about_epos"))+" ")]),(_vm.allowedByScope)?_c('b-dropdown',{attrs:{"append-to-body":"","aria-role":"menu","position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{staticClass:"navbar-link",attrs:{"role":"button"}},[_c('span',[_vm._v(_vm._s(_vm.$t("header.my_profile")))])])]},proxy:true}],null,false,3878433358),model:{value:(_vm.navigation),callback:function ($$v) {_vm.navigation=$$v},expression:"navigation"}},[_c('b-dropdown-item',{attrs:{"custom":"","aria-role":"menuitem"}},[_c('div',{staticClass:"user-meta",domProps:{"innerHTML":_vm._s(
              _vm.richMessage(
                _vm.$t('header.logged_in_as', { user: _vm.userDisplay })
              ).toString()
            )}})]),_c('hr',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',{attrs:{"custom":""}},[_c('b-button',{staticClass:"button-with-icon",attrs:{"type":"is-primary is-light","expanded":""},on:{"click":function($event){return _vm.$router.push(`/me`)}}},[_c('b-icon',{attrs:{"icon":"cog"}}),_c('span',[_vm._v(_vm._s(_vm.$t("profile_page.title")))])],1)],1),(_vm.profile?.clinic_login_enabled)?_c('b-dropdown-item',{attrs:{"custom":""}},[_c('b-button',{staticClass:"button-with-icon",attrs:{"type":"is-warning is-light","expanded":""},on:{"click":function($event){return _vm.$router.push('/clinic')}}},[_c('b-icon',{attrs:{"icon":"clinic-medical"}}),_c('span',[_vm._v(_vm._s(_vm.$t("header.clinic_login")))])],1)],1):_vm._e(),_c('b-dropdown-item',{attrs:{"custom":""}},[_c('b-button',{attrs:{"type":"is-primary","expanded":""},on:{"click":_vm.onLogoutClick}},[_vm._v(_vm._s(_vm.$t("header.logout"))+" ")])],1),_c('b-dropdown-item',{attrs:{"custom":""}},[_c('TenantSwitcher',{attrs:{"profile":_vm.profile},on:{"afterSwitch":_vm.loadProfile}})],1)],1):_vm._e()]},proxy:true}:null],null,true)}),_c('PortalTarget',{attrs:{"name":"warnings"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
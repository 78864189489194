export type AppAuthMethod = "jwt" | "server";

export const serverModeDebugKey = "_enable_server_auth_mode";

/**
 * Returns whether the application is authenticating through a server
 * session or a JWT.
 */
export function getAppAuthMethod(): AppAuthMethod {
  if (process.env.VUE_APP_AUTH_METHOD === "server") {
    return "server";
  }

  // Little helper so you don't have to rebuild the app
  if (
    window.location.hostname === "localhost" &&
    (localStorage.getItem(serverModeDebugKey) ||
      sessionStorage.getItem(serverModeDebugKey))
  ) {
    return "server";
  }

  return window.location.hostname.startsWith("zorgplatform.")
    ? "server"
    : "jwt";
}

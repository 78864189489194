<template>
  <div class="columns">
    <div class="column is-half is-offset-one-quarter" v-if="!saved">
      <b-loading :is-full-page="true" :active="isLoading" />
      <Card :title="$t('api_keys.add').toString()" v-if="!isLoading && apiKey">
        <Form
          :fields="fields"
          :canDelete="false"
          :onSubmitClick="onSubmitClick"
          v-model="apiKey"
        >
          <template v-slot:form-actions>
            <b-button @click="$router.push('/api-keys')">
              {{ $t("common.back") }}
            </b-button>
          </template>
        </Form>
      </Card>
    </div>
    <div class="column is-half is-offset-one-quarter" v-else>
      <Card>
        <p class="is-size-5">{{ $t("api_keys.added_message") }}</p>
        <p class="mt-4">
          <span class="is-family-monospace is-size-3">{{ saved.secret }}</span>
          <b-button
            type="is-info is-light"
            icon-left="copy"
            outlined
            class="ml-4"
            :title="$t('api_keys.copy')"
            v-if="hasClipboard"
            @click="copyToClipboard()"
          ></b-button>
        </p>
        <p class="mt-4 is-size-5">{{ $t("api_keys.added_message_warning") }}</p>

        <template #card-footer>
          <b-button type="is-primary" @click="$router.push('/api-keys')"
            >{{ $t("common.back") }}
          </b-button>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import Card from "@/components/Card.vue";
import Form from "@/components/Form/Form.vue";
import { onMounted, ref } from "vue";
import { FormField } from "@/types/form";
import { ApiKey } from "@/types/api_keys";
import useLoadingStatus from "@/utils/loading-status";
import { createApiKey, getApiKeysForm } from "@/api";

const { isLoading, whileLoading } = useLoadingStatus();

async function onSubmitClick() {
  const result = await createApiKey(apiKey.value);
  saved.value = result.data;
}

const fields = ref([] as FormField[]);
const apiKey = ref({
  name: "",
  expires_at: "",
} as ApiKey);

const saved = ref(null as null | ApiKey);
const hasClipboard = !!navigator.clipboard;

function copyToClipboard() {
  navigator.clipboard.writeText(saved.value?.secret || "");
}

onMounted(async () => {
  await whileLoading(async () => {
    fields.value = (await getApiKeysForm()).data;
  });
});
</script>
